<template>
  <div class="consultant">
    <div class="title">
      <span>咨询管理 / </span> <span class="black">咨询师管理</span>
    </div>
    <el-card class="condition_style2">
      <el-form :inline="true" class="flex" :model="searchForm">
        <el-form-item>
          <el-button type="primary" size="small" @click="add('true')">新增咨询师</el-button>
        </el-form-item>
        <div>
          <el-form-item>
            <el-select v-model="searchForm.type" placeholder="请选择所属分类" clearable>
              <el-option v-for="(item, index) in list" :key="index" :label="item.typeName" :value="item.typeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.flag" placeholder="请选择所属状态" clearable>
              <el-option v-for="(item, index) in status" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.name" placeholder="请输入咨询师名称" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="tableData" style="width: 100%" class="table" @sort-change="sortChange" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(searchForm.page-1) * searchForm.limit + 1}}</template>
        </el-table-column>
        <el-table-column prop="counselorName" label="咨询师名称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="counselorImg" label="头像" min-width="120" align="center">
          <template slot-scope="scope">
            <div class="flex_just_center">
              <img class="img" :src="scope.row.counselorImg" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="counselorZc" label="职称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="consultTitleEntities" label="服务标签" min-width="200" align="center">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.consultTitleEntities" :key="index">
              <div>{{item.titleName}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="counselorGrjj" label="个人简介" min-width="250" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class='myNote'>{{scope.row.counselorGrjj}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="counselorScly" label="擅长领域" min-width="250" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div class='myNote'>{{scope.row.counselorScly}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="语音咨询" min-width="120" align="center" sortable>
          <template slot-scope="scope">
            <div>
              <div><span
                  :style="scope.row.yymoney !=='-1'&&scope.row.yymoney !=='0'?'color:#FFBF00;':''">{{scope.row.yymoney=="-1"?'未开通':scope.row.yymoney=="0"?'免费':scope.row.yymoney}}</span>{{scope.row.yymoney !=="-1"&&scope.row.yymoney !=="0"?'元/小时':""}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="视频咨询" min-width="120" align="center">
          <template slot-scope="scope">
            <div>
              <div><span
                  :style="scope.row.spmoney !=='-1'&&scope.row.spmoney !=='0'?'color:#FFBF00;':''">{{scope.row.spmoney=="-1"?'未开通':scope.row.spmoney=="0"?'免费':scope.row.spmoney}}</span>{{scope.row.spmoney !=="-1"&&scope.row.spmoney !=="0"?'元/小时':""}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="线下咨询" min-width="120" align="center">
          <template slot-scope="scope">
            <div>
              <div><span
                  :style="scope.row.xxmoney !=='-1'&&scope.row.xxmoney !=='0'?'color:#FFBF00;':''">{{scope.row.xxmoney=="-1"?'未开通':scope.row.xxmoney=="0"?'免费':scope.row.xxmoney}}</span>{{scope.row.xxmoney !=="-1"&&scope.row.xxmoney !=="0"?'元/小时':""}}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="counselorTjxj" label="推荐星级" min-width="180" align="center">
          <template slot-scope="scope">
            <el-rate v-model="scope.row.counselorTjxj" disabled text-color="#ff9900"
              score-template="{scope.row.counselorTjxj}"> </el-rate>
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="所属分类" min-width="100" align="center"></el-table-column>
        <el-table-column prop="typeName" label="状态" min-width="100" align="center">
          <template slot-scope="scope">
            {{showState(scope.row.counselorFlag) }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" class="right_btn" @click="cancelRecommend(scope.row,2)">
              {{scope.row.counselorFlag==1?'下架':'上架'}} </el-button>
            <el-button type="text" size="small" @click="add(scope.row.counselorId)">编辑 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
  </div>
</template>

<script>
  import pagination from "../../components/pagination";
  import modal from "../../components/modal";
  import {
    counselorList,
    counselorRecommend,
    updownRecommend,
    detelRecommend,
    counselorTypeList,
  } from "../../api/api";

  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(330),
        total: 0,
        searchForm: {
          // keyword:"",
          type: "",
          flag: "",
          name: "",
          limit: 10,
          // createDate:"",
          // pageFlag:"",//1分页0不分页发布单位
          page: 1,
        },
        tableData: [],

        list: [],
        status: [{
            value: null,
            label: "全部",
          }, {
            value: "0",
            label: "已下架",
          },
          {
            value: "1",
            label: "上架中",
          },
          {
            value: "2",
            label: "待上架",
          },
        ],
        msgDialog: false,
        msgForm: {},
        edit: "",
      };
    },
    watch: {},
    created() {
      if (this.$route.params.form) {
        this.searchForm = JSON.parse(this.$route.params.form)
      }
      this.typeList()
      this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(350)
      })
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    methods: {
      // 排序
      sortChange(column) {
        if (column.order == null) return;
        let bool = column.order == "ascending" ? true : false;
        // this.getList(column.prop, bool)
      },
      // 点击新增、编辑
      add(val) {
        this.$router.push({
          name: "addConsultant",
          params: {
            id: val,
            form: JSON.stringify(this.searchForm)
          }
        });
      },
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.searchForm.pageFlag = this.searchForm.pageFlag ? "0" : "1";
        this.$request(counselorList, this.searchForm).then((res) => {
          if (res.code === "200") {
            let data = res.data;
            this.tableData = data.list;
            this.total = Number(data.totalCount);
            this.searchForm.pageFlag =
              this.searchForm.pageFlag == 0 ? true : false;
            this.showPagination = this.searchForm.pageFlag == 0 ? true : false;
            this.tableData.find((item) => {
              item.counselorTjxj = Number(item.counselorTjxj);
            });
          }
        });
      },
      //获取分类
      typeList() {
        let typeObj = {
          typeId: null,
          typeName: '全部'
        }
        this.$request(counselorTypeList).then((res) => {
          if (res.code === "200") {
            this.list = res.data.list;
            this.list.unshift(typeObj)
          }
        });
      },
      // 推荐、上架、删除
      cancelRecommend(val, bool) {
        let text;
        if (!bool) {
          text = "确认删除？";
        } else if (bool == 1) {
          // counselorSftj 1推荐 0不推荐
          text = val.counselorSftj == 1 ? "确定取消推荐？" : "确定设置为推荐吗？";
        } else if (bool == 2) {
          text = val.counselorFlag == 1 ? "确定下架？" : "确定上架？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.cancel, val);
      },
      cancel(val) {
        let url = !val.bool ?
          detelRecommend(val.counselorId) :
          val.bool === 1 ?
          counselorRecommend(val.counselorId, val.counselorSftj == 1 ? 0 : 1) :
          updownRecommend(val.counselorId, val.counselorFlag == 1 ? 0 : 1);
        this.$request(url).then((res) => {
          if (res.code === "200") {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      showState(val) {
        switch (val) {
          case "0":
            return "下架";
          case "1":
            return "上架";
          case "2":
            return "待上架";
        }
      },
      handleSizeChange(val) {
        this.searchForm.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .consultant {
    .el-rate__icon {
      margin-right: 0px;
    }

    img {
      width: 72px;
      height: 72px;
    }
  }
</style>